// Function to make IE9+ support forEach:
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}



function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    const html = document.documentElement;
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || html.clientHeight) &&
        rect.right <= (window.innerWidth || html.clientWidth)
    );
}

const $pieChart = document.querySelectorAll('.pie-chart');

// Handler when the DOM is fully loaded
document.addEventListener("DOMContentLoaded", function(){

    const bannerNotice = document.getElementById('mtsnb-12298');
    const siteHeader = document.getElementById('site-header');

    if ( bannerNotice ) {
        siteHeader.style.top = bannerNotice.offsetHeight + 'px';
    }

    // VALUE BLOCKS SECTION HEIGHT = CONTENT HEIGHT
    const $valueBlocksSection = document.getElementById('value-blocks-section');
    const $valueBlocksContent = document.getElementById('value-blocks-section-content');
    $valueBlocksSection.style.height = $valueBlocksContent.offsetHeight + 'px';
    const $valueBlocks = document.querySelectorAll('.value-block');

    $valueBlocks[1].classList.add('active');

    $valueBlocks.forEach(function(item) {
        item.addEventListener('mouseover', event => {
            $valueBlocks.forEach(item=>{
                item.classList.remove('active');
            });
            item.classList.add('active');
        });
    });

});

window.addEventListener('scroll', function(e) {
    $pieChart.forEach(el=> {
        if ( isInViewport(el) ) {
            el.classList.add('active');
        } else {
            el.classList.remove('active');
        }
    });
});
